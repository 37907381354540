import { createAction, props } from '@ngrx/store';
import { Product } from '@shared/models/product';

export const createProduct = createAction('[Products Admin] Create Product', props<{ product: Partial<Product> }>());

export const createProductSuccess = createAction(
  '[Products Admin] Create Product Success',
  props<{ product: Product }>()
);

export const createProductFailure = createAction('[Products Admin] Create Product Failure', props<{ error: any }>());
