export const environment = {
  production: true,
  environment: 'production',
  company_name: 'ESET East Africa',
  api_url: 'https://transact-api.esetafrica.com',
  services_api: 'https://services.esetafrica.com',
  cookie_scope: '',
  session_domain: '.esetafrica.com',
  eset_website: 'https://www.eset.com/afr',
  static_url: 'https://static.esetafrica.com/',
  content_url: 'https://cdn-uploads.esetafrica.com',
  login_url: 'https://login.esetafrica.com',
  root_group_id: 'esetafrica',
  currency_symbol: '$',
  worker_locale: 'africa',
  default_country: 'KE',
  auth_token:
    'eyJhbGciOiJIUzUxMiJ9.eyJhcHBsaWNhdGlvbiI6InRyYW5zYWN0IiwidG9rZW4iOiJaTnVJaW5RWWRQYmlmVzhZR1hQZlkxcFdXIn0.QZwmLizymKXMZnQVhefQznNtRWApASQV5P9IquroZ1ydByJMnVFiBdw4D_JYNZfOSjhVhzZcQby_JuLNOe_9MQ',
  paypal_client_id: 'AapNhEqM03UyHBc7HIP-7pCNrO74E9ddSqA7MiI_gMD3SrgkvRHDzZLfMLbCaFuyt1SOOza1wVX6rVR9',
  payu_secure_fields_url: 'https://js.paymentsos.com/v3/latest/secure-fields.min.js',
  payu_public_key: '',
  payu_environment: 'live',
  applications: {
    intranet: {
      name: 'Intranet',
    },
    partnerzone: {
      name: 'PartnerZone',
    },
    reporting: {
      name: 'Reporting',
    },
  },
  resources_link: {
    marketing: 'https://marketing.esetafrica.com',
    api: 'https://isp-api-docs.esetafrica.com/',
  },
  banking: {
    accountname: '4DI Technologies (Pty) Ltd',
    bank: 'FirstRand Bank Limited',
    branch: '254655',
    accountno: '62888084242',
    swift: 'FIRNZAJJXXX',
    accounts_email: 'accounts@esetafrica.com',
    reference_text: 'Please use your Customer ID as payment reference.',
  },
  contact_details: {
    phone_number_display: '+254 111 091600',
    phone_number_actual: '+254111091600',
    partner_email: 'partners@esetafrica.com',
    support_email: 'support@esetafrica.com',
    accounts_email: 'accounts@esetafrica.com',
    support_url_display: 'help.eset.com',
    support_url_actual: 'https://help.eset.com',
  },
};
