import { createAction, props } from '@ngrx/store';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { ProductsFilters } from '@shared/models/products-filters';
import { Product } from '@shared/models/product';

export const loadProductsAdmin = createAction(
  '[Products Admin] Load Products Admin',
  props<{
    filters: ProductsFilters;
    sorting: Sorting;
  }>()
);

export const loadProductsAdminSuccess = createAction(
  '[Products Admin] Load Products Admin Success',
  props<{
    data: Product[];
    filters: ProductsFilters;
    sorting: Sorting;
  }>()
);

export const loadProductsAdminFailure = createAction(
  '[Products Admin] Load Products Admin Failure',
  props<{ error: any }>()
);
