import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Product } from '@shared/models/product';

export const updateProduct = createAction('[Products Admin] Update Products', props<{ product: Product }>());

export const updateProductSuccess = createAction(
  '[Products Admin] Update Products Success',
  props<{ product: Product }>()
);

export const updateProductFailure = createAction('[Products Admin] Update Products Failure', props<{ error: any }>());
