import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';

export const updateCartItemProduct = createAction(
  '[Cart] Update Cart Item Product',
  props<{ cart_id: string; cart_item_id: string; product_code: string; upsell?: boolean }>()
);

export const updateCartItemProductSuccess = createAction(
  '[Cart] Update Cart Item Product Success',
  props<{ cart: Cart }>()
);

export const updateCartItemProductFailure = createAction(
  '[Cart] Update Cart Item Product Failure',
  props<{ error: any }>()
);
